import React from 'react'

const ComingSoon = () => {
    return (
        <div style={styles.container}>
            <img className="img img-fluid" src="./dbev.png" alt="description" style={{ width: "260px" }} />
            <h1 style={styles.title}>Coming Soon!</h1> 
             
        </div>
    );
};
const styles = {
    container: {
        backgroundColor: '#ffffff',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        textAlign: 'center', // Center-align text
        maxWidth: '600px',
        margin: 'auto', // Center container in the viewport
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontSize: '3rem',
        color: 'red',
        marginBottom: '20px'
    },
    text: {
        fontSize: '1.25rem',
        color: 'red',
        marginBottom: '30px'
    }
};
export default ComingSoon